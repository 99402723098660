import hero from '../assets/cute-sticky-cutout.webp';
import herolegacy from '../assets/cute-sticky-cutout.png';
import amazon from '../assets/amazon.png';
import google from '../assets/google.png';
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import aws from '../assets/aws.png';
import docker from '../assets/docker.png';
import experian from '../assets/experian.png';
import php from '../assets/php.png';
import cesca from '../assets/cesca-artists-impression.jpg'
import poppyhero from '../assets/poppy-hero.png'




const images = {
  hero,
  herolegacy,
  amazon,
  google,
  email,
  mobile,
  aws,
  docker,
  experian,
  php,
  cesca,
  poppyhero,
};

export default images;

