import React from 'react';
import { images } from '../../constants';
import './header.css';

const Header = () => (
  <div className="app__header section__padding" id="home">
    <div className="app__header-content">
      <h1 className="gradient__text">Leading web specialists in the online trade-in sector.</h1>
      <p>We build, configure and promote websites that outperform your competition.</p>

      <div className="app__header-content__input">
        <a href="#work"><button type="button">Show me the work</button></a>
      </div>


    </div>

    <div className="app__header-image">
      <img srcSet={images.hero} type="image/webp" src={images.herolegacy} alt="Sticky the cute baby cyber owl offering to boost your website" />
      {/* <img src={images.poppyhero} alt="Rememberance Day Poppy" /> */}
    </div>
  </div>
);

export default Header;
