import React from 'react';


import { images } from '../../constants';
import './brand.css';

const Brand = () => (
  <div className="app__brand section__padding">
    <div>
      <img src={images.php} alt="php-img" />
    </div>
    <div>
      <img src={images.aws} alt="aws-img" />
    </div>
    <div>
      <img src={images.docker} alt="docker-img" />
    </div>
    <div>
    <img src={images.experian} alt="experian-img" />
    </div>
    <div>
      <img src={images.google} alt="google-img" />
    </div>
  </div>
);

export default Brand;

