import React from 'react';

import { Header, About, Testimonial, Work, CTA } from './containers';
import { Navbar, Brand, Footer } from './components';
import { Helmet } from 'react-helmet';
import './App.scss';

const App = () => (
  <div className="app">
    <Helmet>
      <meta charSet='utf-8' />
      <title>Creative Web Design and Development Agency | UK</title>
      <meta name='description' content="Creative Web Design and Development Agency based in the UK. Also offering PPC and SEO promotion. | Sticky" />
    </Helmet>
    <Navbar />
    <Header />
    <Brand />
    <About />
    <Work />
    <Testimonial />
    <CTA />
    <Footer />
  </div>
);

export default App;
