import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from "axios"
import { images } from '../../constants';
import './cta.scss'

const CTA = () => (
    <div className="app__cta-wrapper" id="cta">
        <div className="app__cta-item app__flex">
            <div className="app__cta-staff app__flex">
                <img className="app__cta-img" srcset={images.cesca} alt="francesca-img" />
                <h2 className="gradient__text">Contact Francesca</h2>
                <p>I'm more than happy to have a chat and explore if we are a good fit to potentially work together.</p>
            </div>
            <div className="app__cta-content">           
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        subject: '',
                        msg: '',
                    }}
                    validate={values => {
                        let errors = {}
                        if (!values.name) {
                            errors.name = 'Name is required'
                        } else if (!values.email) {
                            errors.email = 'E-mail is required'
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Invalid email address'
                        } else if (!values.subject) {
                            errors.subject = 'Subject is required'
                        } else if (!values.msg) {
                            errors.msg = 'The email content is empty'
                        }
                        return errors
                    }}

                    onSubmit={async (values, actions) => {
                        actions.setStatus({
                            success: 'Sending email...',
                            css: 'sending'
                        })
                        actions.setSubmitting(false)
                        const response = await axios.post(process.env.REACT_APP_GETFORM_ENDPOINT, {
                            name: values.name,
                            email: values.email,
                            subject: values.subject,
                            msg: values.msg
                        })
                        if (response.status === 200) {
                            actions.setStatus({
                                success: 'Email sent !',
                                css: 'success'
                            })
                        } else {
                            actions.setStatus({
                                success: 'Something went wrong, email not sent !',
                                css: 'error'
                            })
                        }
                    }}
                    render={x => (
                        <Form>
                            <div className="p-input">
                                <Field name='name' type='text' placeholder='Name' />
                            </div>
                            <div className="p-input">
                                <Field name='email' type='email' placeholder='E-mail' />
                            </div>
                            <div className="p-input">
                                <Field name='subject' type='text' placeholder='Subject' />
                            </div>
                            <div className="textarea-field">
                                <Field component='textarea' className="textarea" name='msg' placeholder='Your message' />
                            </div>
                            <ErrorMessage name='name' className='field-validation' component='div' />
                            <ErrorMessage name='email' className='field-validation' component='div' />
                            <ErrorMessage name='subject' className='field-validation' component='div' />
                            <ErrorMessage name='msg' className='field-validation' component='div' />
                            <div className={`form-sending ${x.status ? x.status.css : ''}`}>
                                {x.status ? x.status.success : ''}
                            </div>
                            <button className="app__cta-sign button" type='submit' disabled={x.isSubmitting}>Submit</button>
                        </Form>
                    )}
                />
            </div>
        </div>
    </div>
)
export default CTA;