import React from 'react';
import logo from '../../logo.svg';
import './footer.scss';

const Footer = () => (
  <div className="app__footer section__padding">
    <div className="app__footer-heading">
    </div>

 

    <div className="app__footer-links">
      <div className="app__footer-links_logo">
        <img src={logo} alt="app_logo" />
        <p>PJ Owen (Partner) </p>
        <p>FS Owen (Partner) </p>
        <p>T/A Sticky New Media (Sticky)</p>
        <p>Vat Number: GB615350270 </p>
      </div>

      <div className="app__footer-links_div">
      <h4>Contact</h4>
        <p>Gatcombe House, Copnor Road, <br /> Portsmouth, Hampshire PO3 5EJ</p>
        <p>UK 02393 190 002</p>
        <p>Intl +44 2392 380 457</p>
        <p>hello@mysticky.co.uk</p>
      </div>
      
      <div className="app__footer-links_div" id="links">
      <h4>Links</h4>
      <p><a href="https://mysticky.grouphub.com">MySticky Sign In</a></p>
      </div>

      <div className="app__footer-links_div" id="terms">
      <h4>Terms</h4>
      <p><a href="https://s3.eu-west-1.amazonaws.com/www.mysticky.co.uk/public/Sticky-Website-Development-Terms-Conditions.pdf" target="_blank" rel="noreferrer">T&Cs New Website or Major Changes</a></p>
      <p><a href="https://s3.eu-west-1.amazonaws.com/www.mysticky.co.uk/public/Sticky-Maintenance-Modification-Terms-Conditions.pdf" target="_blank" rel="noreferrer">T&Cs Existing Site or Minor/Medium Changes</a></p>
      </div>


      {/* <div className="app__footer-links_div">

      </div> */}
    </div>

    <div className="app__footer-copyright">
      <p>@2023 Sticky. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
